









































































































































































































































































































































import { Component } from 'vue-property-decorator';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import PillWidget from '@/components/pill/PillWidget.vue';
import MenuModel from '@/models/MenuModel';
import { MeetingRequestsInboxMenuActions } from '@/utils/enums/MeetingRequestsInboxMenuActions';
import { mixins } from 'vue-class-component';
import MeetingRequestWrapper from '@/components/wrappers/MeetingRequestWrapper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { format } from 'date-fns';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import StandardModal from '@/components/modals/StandardModal.vue';
import CompanyCalendarViewComponent
  from '@/components/company-meeting/CompanyCalendarViewComponent.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import Variant from '@/utils/enums/Variant';
import CompanyCalendarDetailCommentsComponent
  from '@/components/company-meeting/CompanyCalendarDetailCommentsComponent.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import EntityType from '@/utils/enums/EntityType';

const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  computed: {
    CommunityUser() {
      return CommunityUser;
    },
  },
  components: {
    CompanyCalendarDetailCommentsComponent,
    PillComponent,
    CompanyCalendarViewComponent,
    StandardModal,
    ButtonComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
    PillWidget,
    FontAwesomeComponent,
    InputSearchComponent,
  },
})
export default class CompanyCalendarDetailComponent extends mixins(
  MeetingRequestWrapper, BreakpointWrapper,
) {
  @chatStore.Action
  private openSelectedConversation!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string | null;
    targetType?: string | null;
    targetData?: object;
  }) => Promise<void>;

  @State
  private readonly exhibitorPortalDomain!: string;

  private meetingRequestsInboxMenuActions = MeetingRequestsInboxMenuActions;

  private archive = this.trash;

  private Variant = Variant;

  private activityTabs = {
    COMMENTS: 'COMMENTS',
    HISTORY: 'HISTORY',
  };

  private selectedTab = this.activityTabs.COMMENTS;

  get badgeStatus(): string {
    if (this.archive) return `${this.$t('calendar.tabs.requests.archived')}`;
    if (this.isOpen) return `${this.$t('calendar.tabs.requests.incoming')}`;
    if (this.isCompleted) return `${this.$t('calendar.tabs.requests.completed')}`;
    if (this.isScheduled) return `${this.$t('calendar.tabs.requests.scheduled')}`;
    if (this.isPending) return `${this.$t('calendar.tabs.requests.pending')}`;
    return '';
  }

  get badgeTimeSlot(): string {
    let startDate: Date;
    let endDate: Date;
    if (this.meeting?.startTimestamp && this.meeting?.endTimestamp) {
      startDate = DateTimeHelper.utcToZonedTimeDate(this.meeting.startTimestamp * 1000, this.selectedTzName);
      endDate = DateTimeHelper.utcToZonedTimeDate(this.meeting.endTimestamp * 1000, this.selectedTzName);
    } else if (this.meeting?.startTime && this.meeting?.endTime) {
      startDate = DateTimeHelper.utcToZonedTimeDate(`${this.meeting.startTime}Z`, this.selectedTzName);
      endDate = DateTimeHelper.utcToZonedTimeDate(`${this.meeting.endTime}Z`, this.selectedTzName);
    } else {
      return '';
    }
    return `${format(startDate, 'MMM dd, hh:mm a')} - ${format(endDate, 'hh:mm a')}`;
  }

  get availability(): string {
    let startTime: Date;
    let endTime: Date;
    if (this.startTimestamp) {
      startTime = DateTimeHelper.utcToZonedTimeDate(this.startTimestamp * 1000, this.selectedTzName);
      endTime = DateTimeHelper.utcToZonedTimeDate(this.endTimestamp * 1000, this.selectedTzName);
    } else if (this.startTime) {
      startTime = DateTimeHelper.utcToZonedTimeDate(`${this.startTime}Z`, this.selectedTzName);
      endTime = DateTimeHelper.utcToZonedTimeDate(`${this.endTime}Z`, this.selectedTzName);
    } else {
      return '';
    }
    return `${format(startTime, 'MMM dd, yyy - hh:mm a')} - ${format(endTime, 'hh:mm a')}`;
  }

  get requestingToMeetUserName(): string {
    if (!this.companyRep) {
      return `${this.$t('calendar.tabs.requests.meeting-detail.anyone')} ${this.exhibitor.name}`;
    }
    return CommunityUser.hydrate(this.companyRep)?.fullName || '';
  }

  get requestingToMeetUserImage(): string | undefined {
    if (!this.companyRep) {
      return FileResourceHelper.getFullPath(this.exhibitor.logoFileResource);
    }
    return this.getUserProfilePicture(this.companyRep);
  }

  private get menuList(): MenuModel[] {
    const menus: MenuModel[] = [];
    if (!this.archive) {
      if (this.meeting) {
        menus.push({
          uid: '0',
          action: MeetingRequestsInboxMenuActions.EDIT,
          icon: 'fa-pen',
          name: 'calendar.tabs.requests.action-items.edit',
        });
      }
      if (this.authUser
        && this.user
        && this.authUser.uid !== this.user.uid) {
        menus.push({
          uid: '3',
          action: MeetingRequestsInboxMenuActions.CHAT,
          icon: 'fa-comment-dots',
          name: 'calendar.tabs.requests.action-items.message',
        });
      }
      menus.push({
        uid: '1',
        action: MeetingRequestsInboxMenuActions.ARCHIVE,
        icon: 'fa-folder-download',
        name: 'calendar.tabs.requests.action-items.archive',
      });
    } else if (this.authUser
        && this.user
        && this.authUser.uid !== this.user.uid) {
      menus.push({
        uid: '3',
        action: MeetingRequestsInboxMenuActions.CHAT,
        icon: 'fa-comment-dots',
        name: 'calendar.tabs.requests.action-items.message',
      });
    }
    return menus;
  }

  // eslint-disable-next-line class-methods-use-this
  private getUserProfilePicture(user: CommunityUser): string | undefined {
    if (user) {
      const hydrated = CommunityUser.hydrate(user);
      if (hydrated) {
        return hydrated.profilePicture;
      }
    }
    return undefined;
  }

  private openUserDetail(memberId: string): void {
    if (memberId) {
      const route = this.$router.resolve({
        name: 'exhibitor-portal-user',
        params: { memberId },
      }).href;
      const url = new URL(route, this.exhibitorPortalDomain);
      window.open(url.href, '_blank');
    }
  }

  private doAction(action: string | null): void {
    switch (action) {
      case MeetingRequestsInboxMenuActions.SCHEDULE:
        this.scheduleMeeting();
        break;
      case MeetingRequestsInboxMenuActions.EDIT:
        this.editMeeting();
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      case MeetingRequestsInboxMenuActions.ARCHIVE:
        this.archiveMeetingRequest({ uid: this.uid, trash: true });
        this.archive = true;
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;

      case MeetingRequestsInboxMenuActions.CHAT:
        this.openSelectedConversation({
          myUid: this.authUser.uid,
          users: [
            this.authUser,
            this.user,
          ],
          groupType: [GroupType.GROUP],
          targetType: EntityType.MEETING_REQUEST,
          targetUid: this.uid,
        });
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      default:
        break;
    }
  }

  private created(): void {
    // console.log('FINALLY --------------------- ', this.$route.params.companyId);
    this.archive = this.trash;
    if (!this.readState) {
      this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
        .then(() => {
          this.$eventsBus.emit('on-update-company-meeting-request-count');
        });
    }
  }
}
