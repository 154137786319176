





































































































import { Component } from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import MeetingRequestWrapper from '@/components/wrappers/MeetingRequestWrapper';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import { mixins } from 'vue-class-component';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import MenuModel from '@/models/MenuModel';
import { MeetingRequestsInboxMenuActions } from '@/utils/enums/MeetingRequestsInboxMenuActions';
import { format } from 'date-fns';
import CommunityUser from '@/models/graphql/CommunityUser';
import GroupType from '@/utils/enums/chat/GroupType';
import { namespace } from 'vuex-class';
import EntityType from '@/utils/enums/EntityType';

const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonComponent,
    StandardModal,
    ButtonIconComponent,
  },
})
export default class CompanyCalendarListItemComponent extends mixins(MeetingRequestWrapper, BreakpointWrapper) {
  private isHovered = false;

  @chatStore.Action
  private openSelectedConversation!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string | null;
    targetType?: string | null;
    targetData?: object;
  }) => Promise<void>;

  get isUnread(): boolean {
    return !this.readState;
  }

  get badgeStatus(): string {
    if (this.isCompleted) return `${this.$t('calendar.tabs.requests.completed')}`;
    if (this.isScheduled) return `${this.$t('calendar.tabs.requests.scheduled')}`;
    if (this.isPending) return `${this.$t('calendar.tabs.requests.pending')}`;
    return '';
  }

  get badgeDateString(): string {
    if (!this.meeting?.startTimestamp) return '';
    const startDate = new Date(this.meeting.startTimestamp * 1000);
    return format(startDate, 'EEE, MMM dd');
  }

  get badgeTimeSlot(): string {
    if (!this.meeting?.startTimestamp || !this.meeting?.endTimestamp) return '';
    const startDate = new Date(this.meeting.startTimestamp * 1000);
    const endDate = new Date(this.meeting.endTimestamp * 1000);
    return `${format(startDate, 'hh:mm a')} - ${format(endDate, 'hh:mm a')}`;
  }

  private get menuList(): MenuModel[] {
    const menus: MenuModel[] = [];
    if (!this.trash) {
      if (!this.meeting) {
        menus.push({
          uid: '0',
          action: MeetingRequestsInboxMenuActions.SCHEDULE,
          icon: 'fa-calendar-plus',
          name: 'calendar.tabs.requests.action-items.schedule',
        });
      } else {
        menus.push({
          uid: '0',
          action: MeetingRequestsInboxMenuActions.EDIT,
          icon: 'fa-pen',
          name: 'calendar.tabs.requests.action-items.edit',
        });
      }
      if (this.authUser
        && this.user
        && this.authUser.uid !== this.user.uid) {
        menus.push({
          uid: '3',
          action: MeetingRequestsInboxMenuActions.CHAT,
          icon: 'fa-comment-dots',
          name: 'calendar.tabs.requests.action-items.message',
        });
      }
      menus.push({
        uid: '1',
        action: MeetingRequestsInboxMenuActions.ARCHIVE,
        icon: 'fa-folder-download',
        name: 'calendar.tabs.requests.action-items.archive',
      });
    } else {
      if (this.authUser
        && this.user
        && this.authUser.uid !== this.user.uid) {
        menus.push({
          uid: '3',
          action: MeetingRequestsInboxMenuActions.CHAT,
          icon: 'fa-comment-dots',
          name: 'calendar.tabs.requests.action-items.message',
        });
      }
      menus.push({
        uid: '2',
        action: MeetingRequestsInboxMenuActions.INBOX,
        icon: 'fa-inbox-out',
        name: 'calendar.tabs.requests.action-items.inbox',
      });
    }
    return menus;
  }

  onMeetingRequestClick(): void {
    this.$emit('on-click', MeetingRequest.hydrate(this.$props));
  }

  private doAction(action: string | null): void {
    switch (action) {
      case MeetingRequestsInboxMenuActions.SCHEDULE:
        this.scheduleMeeting();
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      case MeetingRequestsInboxMenuActions.EDIT:
        this.editMeeting();
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      case MeetingRequestsInboxMenuActions.ARCHIVE:
        this.archiveMeetingRequest({ uid: this.uid, trash: true });
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      case MeetingRequestsInboxMenuActions.INBOX:
        this.archiveMeetingRequest({ uid: this.uid, trash: false });
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      case MeetingRequestsInboxMenuActions.CHAT:
        this.openSelectedConversation({
          myUid: this.authUser.uid,
          users: [
            this.authUser,
            this.user,
          ],
          groupType: [GroupType.GROUP],
          targetType: EntityType.MEETING_REQUEST,
          targetUid: this.uid,
        });
        if (!this.readState) {
          this.markMeetingRequestAsRead({ uid: this.uid, companyUid: this.$route.params.companyId })
            .then(() => {
              this.$eventsBus.emit('on-update-company-meeting-request-count');
            });
        }
        break;
      default:
        break;
    }
  }
}
