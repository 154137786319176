













































































































































































































































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import {
  Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import FeedType from '@/utils/enums/feed/FeedType';
import FeedAction from '@/utils/enums/feed/FeedAction';
import CommunityUser from '@/models/graphql/CommunityUser';
import Exhibitor from '@/models/graphql/Exhibitor';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { TranslateResult } from 'vue-i18n';
import FeedItem from '@/models/graphql/FeedItem';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  fromUnixTime,
} from 'date-fns';
import { namespace, State } from 'vuex-class';
import PillComponent from '@/components/pill/PillComponent.vue';
import FeedFileResourceUpdateComponent from '@/components/feed/FeedFileResourceUpdateComponent.vue';
import FeedContent from '@/components/feed/FeedContent.vue';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import SubEdition from '@/models/graphql/SubEdition';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import GroupType from '@/utils/enums/chat/GroupType';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import FeedItemCreatorModal from '@/components/feed/creator/FeedItemCreatorModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import SendConnectionRequestModal from '@/components/modals/SendConnectionRequestModal.vue';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import Channel from '@/models/graphql/Channel';
import FeedItemReportModal from '@/components/feed/FeedItemReportModal.vue';
import FeedPostReactionsComponent from '@/components/feed/FeedPostReactionsComponent.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import EntityType from '@/utils/enums/EntityType';
import FeedPost from '@/models/graphql/FeedPost';
import { CommunityUserFollowFilter } from '@/graphql/_Filters/CommunityUserFollowFilter';
import UpToDateFeedComponent from '@/components/feed/UpToDateFeedComponent.vue';
import JoinedFeedItemCardComponent from '@/components/feed/JoinedFeedItemCardComponent.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');
const communityUserFollowStore = namespace('CommunityUserFollowStore');
const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const pollStore = namespace('PollStore');
const toastStore = namespace('ToastStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    SendConnectionRequestModal,
    FeedPostReactionsComponent,
    FeedFileResourceUpdateComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
    FontAwesomeComponent,
    DropdownMenu,
    DropdownMenuItem,
    PillComponent,
    FeedContent,
    FeedItemCreatorModal,
    FeedItemReportModal,
    ConfirmModal,
    UpToDateFeedComponent,
    JoinedFeedItemCardComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedItemComponent extends VueBaseWidget {
  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @permissionManagerStore.Getter
  protected canPostInCompany!: (companyUid: string) => boolean;

  @permissionManagerStore.Getter
  protected canPostInSubEdition!: (subEditionId: string) => boolean;

  @Inject({ from: 'feedType' })
  private feedType!: { value: string };

  @Prop({ required: true })
  private uid!: string;

  @Prop({
    required: true,
    default: null,
  })
  private aggregateKey!: string;

  @Prop({ required: true })
  private initiator!: CommunityUser | Exhibitor | SubEdition | Channel;

  @Prop({ required: true })
  private _feedItems!: FeedItem[];

  @Prop({
    required: false,
    default: null,
  })
  private pinned!: boolean;

  @Prop({ required: true })
  private updatedTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private _isBookmarked!: string;

  @Prop({
    required: false,
    default: null,
  })
  private _isFollowed!: string;

  @Prop({
    required: false,
    default: null,
  })
  private showUpToDate!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isModalEnabled!: boolean;

  @Prop({ default: null })
  private showCommentWithId!: string | null;

  @feedItemWrapperStore.Action
  private deletePost!: (payload: {
    wrapperUid: string;
    triggeredUid: string | undefined;
  }) => Promise<void>;

  @feedItemWrapperStore.Action
  private hidePost!: (payload: { userUid: string; wrapperUid: string }) => void;

  @feedItemWrapperStore.Mutation
  private removePost!: (uid: string) => void;

  @feedItemWrapperStore.Mutation
  private pinPost!: (wrapper: FeedItemWrapper) => void;

  @feedItemWrapperStore.Mutation
  private unpinPost!: (wrapper: FeedItemWrapper) => void;

  @feedItemWrapperStore.Action
  private updateFeedItemWrapper!: (payload: {
    authUserId: string;
    attributes: Partial<FeedItemWrapper>;
  }) => Promise<FeedItemWrapper | undefined>;

  private isBookmarked = false;

  @communityUserFollowStore.Action
  private unFollowEntity!: (payload: { userId: string; entityId: string }) => Promise<void>;

  @communityUserFollowStore.Action
  private follow!: (payload: CommunityUserFollowFilter) => Promise<CommunityUserFollow | undefined>;

  private isFollowing = false;

  private removeUpToDate = false;

  private isAuthorRepostFollowing = false;

  @communityUserConnectionStore.Action
  private fullyDisconnect!: (payload: {
    uid: string;
    messageLookupArgs: object;
    toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyConnect!: (payload: {
    connectionArgs: object; newConversationArgs: object;
  }) => Promise<CommunityUserConnection | undefined>;

  @feedItemWrapperStore.Mutation
  private setFeedInitiator!: (initiator: CommunityUser | Exhibitor | SubEdition | Channel) => void;

  @pollStore.Action('delete')
  private deletePoll!: (uid: string) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private connected: CommunityUserConnection | undefined | null = null;

  private connectionPending: CommunityUserConnection | undefined | null = null;

  @State
  private dateLocale!: Locale;

  private FeedActionType = FeedAction;

  private isDeleted = false;

  private initiatorUser = this.initiator || undefined;

  private get isGlobal(): boolean {
    return [FeedType.GLOBAL, FeedType.TOPIC].includes(this.feedType.value as FeedType);
  }

  private get isTrueGlobal(): boolean {
    return this.feedType.value as FeedType === FeedType.GLOBAL;
  }

  private get repostParentFeedItemWrapper(): FeedItemWrapper | undefined {
    const feedPost = this._feedItems.length > 0 ? this._feedItems[0].triggered as FeedPost : null;
    if (feedPost && feedPost.repostPost && feedPost.repostPost._feedItem) {
      return (feedPost.repostPost._feedItem as FeedItem).wrapper;
    }
    return undefined;
  }

  private get isRepost(): boolean {
    const feedPost = this._feedItems.length > 0 ? this._feedItems[0].triggered as FeedPost : null;
    return !!(feedPost && feedPost.type === FeedPostType.REPOST);
  }

  private get isTopic(): boolean {
    const feedPost = this._feedItems[0].triggered as FeedPost;
    if (this.feedType.value === FeedType.TOPIC) return false;
    return !!(feedPost.topic);
  }

  private get repostAuthorName(): string {
    const feedPost = this._feedItems[0].triggered as FeedPost;
    switch (feedPost.initiator?.__typename) {
      case FeedType.COMMUNITY_USER: {
        const user = CommunityUser.hydrate(feedPost.initiator as CommunityUser);
        return user.fullName;
      }
      case FeedType.EXHIBITOR: {
        const exhibitor = feedPost.initiator as Exhibitor;
        return (exhibitor.name) ? exhibitor.name : '';
      }
      case FeedType.SUB_EDITION: {
        const subEdition = feedPost.initiator as SubEdition;
        return (subEdition.name) ? subEdition.name : '';
      }
      case FeedType.CHANNEL: {
        const channel = feedPost.initiator as Channel;
        return (channel.name) ? channel.name : '';
      }
      default:
        return '';
    }
  }

  private get feedHeaderContentText(): string {
    if (this.pinned) {
      return String(this.$t('app.feed-page.pinned.message'));
    }
    if (this.isRepost) {
      return String(this.$t('app.feed-page.repost.header-title'));
    }
    if (this.isTopic) {
      return String(this.$t('app.feed-page.topic.header-title'));
    }
    return String(this.$t('app.feed-page.pinned.message'));
  }

  private get topicName(): string {
    const feedPost = this._feedItems[0].triggered as FeedPost;
    if (feedPost.topic && feedPost.topic.name) {
      return feedPost.topic.name;
    }
    return '';
  }

  private get feedItemElement(): FeedItemWrapper {
    return {
      aggregateKey: this.aggregateKey,
      uid: this.uid,
      initiator: this.initiator,
      _feedItems: this._feedItems,
      updatedTimestamp: this.updatedTimestamp,
      _isBookmarked: this._isBookmarked,
    };
  }

  private get header(): object {
    const attributes = {
      initiatorImage: '',
      title: '',
      subtitle: '',
      time: '',
      firstName: '',
      lastName: '',
    };
    if (this.isRepost) {
      const feedPost = this._feedItems[0].triggered as FeedPost;
      if (!feedPost.repostPost) {
        return {
          initiatorImage: '',
          title: '',
          subtitle: '',
          time: '',
          firstName: '',
          lastName: '',
        };
      }
      if (!feedPost.repostPost?.initiator) {
        return {
          initiatorImage: '',
          title: '',
          subtitle: '',
          time: '',
          firstName: '',
          lastName: '',
        };
      }
      switch (feedPost.repostPost.initiator.__typename) {
        case FeedType.COMMUNITY_USER:
        case FeedType.PROFILE: {
          const user = CommunityUser.hydrate(feedPost.repostPost.initiator) as CommunityUser;
          attributes.initiatorImage = user.profilePicture;
          attributes.title = user.fullName;
          attributes.subtitle = user.jobTitle as string;
          attributes.firstName = user.firstName as string;
          attributes.lastName = user.lastName as string;
        }
          break;
        case FeedType.EXHIBITOR: {
          const exhibitor = feedPost.repostPost.initiator as Exhibitor;
          attributes.initiatorImage = FileResourceHelper.getFullPath(exhibitor.logoFileResource, 'w40');
          attributes.title = exhibitor.name as string;
          attributes.subtitle = this.followerSubtitle(exhibitor._followerCount as number) as string;
        }
          break;
        case FeedType.SUB_EDITION: {
          const subEdition = feedPost.repostPost.initiator as SubEdition;
          attributes.initiatorImage = FileResourceHelper.getFullPath(subEdition.logoFileResource, 'w40');
          attributes.title = subEdition.name as string;
          attributes.subtitle = this.followerSubtitle(subEdition._followerCount as number) as string;
        }
          break;
        case FeedType.CHANNEL: {
          const channel = feedPost.repostPost.initiator as Channel;
          attributes.initiatorImage = FileResourceHelper.getFullPath(channel.thumbnailFileResource, 'w40');
          attributes.title = channel.name as string;
          attributes.subtitle = this.followerSubtitle(channel._followerCount as number) as string;
        }
          break;
        default:
          break;
      }
    } else {
      switch (this.initiator.__typename) {
        case FeedType.COMMUNITY_USER:
        case FeedType.PROFILE: {
          const user = CommunityUser.hydrate(this.initiator) as CommunityUser;
          attributes.initiatorImage = user.profilePicture;
          attributes.title = user.fullName;
          attributes.subtitle = user.jobTitle as string;
          attributes.firstName = user.firstName as string;
          attributes.lastName = user.lastName as string;
        }
          break;
        case FeedType.EXHIBITOR: {
          const exhibitor = this.initiator as Exhibitor;
          attributes.initiatorImage = FileResourceHelper.getFullPath(exhibitor.logoFileResource, 'w40');
          attributes.title = exhibitor.name as string;
          attributes.subtitle = this.followerSubtitle(exhibitor._followerCount as number) as string;
        }
          break;
        case FeedType.SUB_EDITION: {
          const subEdition = this.initiator as SubEdition;
          attributes.initiatorImage = FileResourceHelper.getFullPath(subEdition.logoFileResource, 'w40');
          attributes.title = subEdition.name as string;
          attributes.subtitle = this.followerSubtitle(subEdition._followerCount as number) as string;
        }
          break;
        case FeedType.CHANNEL: {
          const channel = this.initiator as Channel;
          attributes.initiatorImage = FileResourceHelper.getFullPath(channel.thumbnailFileResource, 'w40');
          attributes.title = channel.name as string;
          attributes.subtitle = this.followerSubtitle(channel._followerCount as number) as string;
        }
          break;
        default:
          break;
      }
    }

    attributes.time = this.dateReceived;
    return attributes;
  }

  private get actionType(): string | undefined {
    if (
      (this.aggregateKey
        && this.hasMoreThanOneActionType)
      || this.aggregateKey === FeedAction.USER_CONNECTIONS_UPDATED
    ) {
      return this.aggregateKey;
    }
    return this._feedItems.length > 0 ? (this._feedItems[0] as unknown as FeedItem).action : undefined;
  }

  private get isPersonalPage(): boolean {
    if (!this.authUser) {
      return false;
    }
    return this.initiator.uid === this.authUser.uid
      && this.feedType.value === FeedType.PROFILE;
  }

  private get isEditable(): boolean {
    if (!this.authUser) {
      return false;
    }
    return this.actionType === this.FeedActionType.FEEDPOST_CREATED
      && (this.initiator.uid === this.authUser.uid
        || this.canPostInCompany(this.initiator.uid)
        || this.canPostInSubEdition(this.initiator.uid)
      );
  }

  private get isDeletable(): boolean {
    if (!this.authUser) {
      return false;
    }
    return this.actionType === this.FeedActionType.FEEDPOST_CREATED
      && (this.initiator.uid === this.authUser.uid
        || this.canPostInCompany(this.initiator.uid)
        || this.canPostInSubEdition(this.initiator.uid)
        || this.authUser.isAnOrganiser
      );
  }

  private get navigateTo(): object | string {
    const feedPost = this._feedItems.length > 0 ? this._feedItems[0].triggered as FeedPost : null;
    const initiator = (this.isRepost) ? feedPost?.repostPost?.initiator : this.initiator;
    if (!initiator) return '';
    switch (initiator.__typename) {
      case FeedType.COMMUNITY_USER:
        if (this.authUser && this.authUser.uid === initiator.uid) {
          return { name: 'my-profile' };
        }
        return {
          name: 'member-detail',
          params: { memberId: initiator.uid },
        };
      case FeedType.EXHIBITOR:
        return {
          name: 'company-detail',
          params: { companyId: initiator.uid },
        };
      case FeedType.SUB_EDITION: {
        const router = this.$router.resolve({
          name: 'event-detail',
          params: { editionCode: (initiator as SubEdition).code || '' },
        });
        if (router.route.matched.length > 0) {
          return router.route;
        }
        return { path: '/' };
      }
      case FeedType.CHANNEL:
        return {
          name: 'channel-detail',
          params: { channelId: initiator.uid },
        };
      default:
        return { path: '/' };
    }
  }

  private get navigateToRepostHeader(): object | string {
    switch (this.initiator.__typename) {
      case FeedType.COMMUNITY_USER:
        if (this.authUser && this.authUser.uid === this.initiator.uid) {
          return { name: 'my-profile' };
        }
        return {
          name: 'member-detail',
          params: { memberId: this.initiator.uid },
        };
      case FeedType.EXHIBITOR:
        return {
          name: 'company-detail',
          params: { companyId: this.initiator.uid },
        };
      case FeedType.SUB_EDITION:
        if (this.$router.resolve({
          name: 'event-detail',
          params: { editionCode: (this.initiator as SubEdition).code || '' },
        }).route.matched.length) {
          return {
            name: 'event-detail',
            params: { editionCode: (this.initiator as SubEdition).code || '' },
          };
        }
        return { path: '/' };
      case FeedType.CHANNEL:
        return {
          name: 'channel-detail',
          params: { channelId: this.initiator.uid },
        };
      default:
        return { path: '/' };
    }
  }

  private get hasMoreThanOneActionType(): boolean {
    return this._feedItems.findIndex((feedItem) => this._feedItems[0].action !== feedItem.action) !== -1
      && this.aggregateKey !== null;
  }

  private get feedHasAtLeastOneTriggered(): boolean {
    const hasAtLeastOne = this._feedItems.findIndex((feedItem) => feedItem.triggered);
    return hasAtLeastOne !== -1;
  }

  private get canNotDisplayConnection(): boolean {
    return !!['Exhibitor', 'SubEdition', 'Channel'].find((element) => element === this.initiatorUser.__typename);
  }

  private get isNotSelf(): boolean {
    return this.initiator.uid !== this.authUser.uid;
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const updatedTime = fromUnixTime(this.updatedTimestamp);
    if (differenceInSeconds(today, updatedTime) < 60) {
      return `${this.$t('app.feed-page.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, updatedTime);
    if (diffInMinutes < 60) {
      return `${this.$t('app.feed-page.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, updatedTime);
    if (diffInDays < 1) {
      return `${this.$t('app.feed-page.time-ago.hours', {
        number: differenceInHours(today, updatedTime),
      })}`;
    }

    if (diffInDays < 7) {
      return `${this.$t('app.feed-page.time-ago.days', { number: diffInDays })}`;
    }

    return format(updatedTime, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private get showJoinedCard(): boolean {
    return !![
      'TOPIC_CREATED',
      'EXHIBITOR_CREATED',
      'USER_CREATED',
      'CHANNEL_CREATED',
    ].find((item) => item === this.aggregateKey);
  }

  private get createdFormattedDate(): string {
    const updatedTime = fromUnixTime(this.updatedTimestamp);
    return format(updatedTime, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  mounted(): void {
    this.setIsFollowing();
    this.setConnection();
  }

  private setIsFollowing(): void {
    this.isFollowing = !!this.initiator._isFollowed;
    if (this._feedItems[0]) {
      const { authorUser } = this._feedItems[0].triggered as FeedPost;
      if (this.isRepost && authorUser?.uid) {
        this.isAuthorRepostFollowing = !!authorUser?._isFollowed;
      }
    }
  }

  @Watch('_isBookmarked', { immediate: true })
  private setIsBookmarked(): void {
    this.isBookmarked = !!this._isBookmarked;
  }

  @Watch('initiator', { deep: true })
  private setConnection(): void {
    if (this.initiator && this.initiator.__typename) {
      const user = this.initiator as CommunityUser;
      this.connected = user._ourConnection?.linkState === ConnectionStatusType.ACCEPTED ? user._ourConnection : null;
      this.connectionPending = user._ourConnection?.linkState === ConnectionStatusType.INVITED ? user._ourConnection : null;
    }
  }

  private editPost(): void {
    this.$bvModal.show(`edit-feed-item-modal-${this.uid}`);
  }

  private onHidePost(isRepostItem = false): void {
    if (!isRepostItem && this.isRepost && this.repostParentFeedItemWrapper) {
      this.hidePost({
        userUid: this.authUser.uid,
        wrapperUid: this.repostParentFeedItemWrapper.uid,
      });
      this.hidePost({
        userUid: this.authUser.uid,
        wrapperUid: this.uid,
      });
    } else {
      this.hidePost({
        userUid: this.authUser.uid,
        wrapperUid: this.uid,
      });
    }
  }

  private deleteThisPost(): void {
    this.isDeleted = true;
    if (this._feedItems[0]?.triggered?.uid) {
      this.deletePost({
        wrapperUid: this.uid,
        triggeredUid: this._feedItems[0]?.triggered?.uid,
      })
        .then(() => {
          // Temporary solution. This action need to be done by the backend
          const post = this._feedItems[0]?.triggered as FeedPost;
          if (post && post.polls?.length) {
            this.deletePoll(post.polls[0].uid || '');
          }
        });
    }
  }

  private followerSubtitle(count: number): TranslateResult | string {
    if (count === 1) {
      return `${count} ${this.$t('app.feed-page.follower')}`;
    }
    return `${count} ${this.$t('app.feed-page.followers')}`;
  }

  private toggleBookmarkInTheWidget(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isBookmarked) {
      this.isBookmarked = false;
      this.unBookmark({
        uid: this._isBookmarked,
      })
        .then(() => {
          this.$root.$emit('remove-feed-item-bookmark', this.uid);
        })
        .catch(() => {
          this.isBookmarked = true;
        });
    } else {
      this.isBookmarked = true;
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.FEED_ITEM_WRAPPER,
      })
        .then(() => {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.FEED_ITEM_WRAPPER,
            StatLoggerActions.ADD,
            '',
            -1,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        })
        .catch(() => {
          this.isBookmarked = false;
        });
    }
  }

  private unfollow(isRepostItem = false): void {
    let entityId = this.initiator.uid;
    const { authorUser } = this._feedItems[0].triggered as FeedPost;
    if (isRepostItem && authorUser) {
      entityId = authorUser.uid;
    }
    if (this.isFollowing && entityId) {
      this.unFollowEntity({
        userId: this.authUser.uid,
        entityId,
      })
        .then(() => {
          this.addNewAction({
            type: ToastActionType.UNFOLLOW,
            item: (this.header as { title: string }).title,
          });
        });
    }
  }

  private onFollowUser(): void {
    if (!this.isFollowing && this.authUser.uid !== this.initiator.uid) {
      this.follow({
        userId: this.authUser.uid,
        linkedUserId: this.initiator.uid,
        entityType: EntityType.USER,
      })
        .then(() => {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.USER,
            StatLoggerActions.ADD,
            '',
            -1,
            this.initiator.uid,
            StatLoggerCategories.FOLLOW,
            this.$i18n.locale,
          );
          this.addNewAction({
            type: ToastActionType.FOLLOW,
            item: (this.header as { title: string }).title,
          });
        });
    }
  }

  private removeConnection(): void {
    const auth = (({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }) => ({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }))(this.authUser) as CommunityUser;
    if (this.connected && this.initiatorUser) {
      const temp = this.connected;
      this.connected = null;
      const user = this.initiatorUser as CommunityUser;
      this.fullyDisconnect({
        uid: temp.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.initiatorUser.uid,
            firstName: user.firstName,
            lastName: user.lastName,
            pictureFileResource: user.pictureFileResource,
          } as CommunityUser],
          groupType: GroupType.DIRECT,
        },
        toastUserFullName: `${user.firstName} ${user.lastName}`,
      })
        .then(() => {
          this.connected = null;
        })
        .catch(() => {
          this.connected = temp;
        });
    }
  }

  private addConnection(message: string | null = null): void {
    const temp = { ...this.connected } as CommunityUserConnection;
    temp.linkState = ConnectionStatusType.INVITED;
    this.connectionPending = temp;
    this.fullyConnect({
      connectionArgs: {
        userId: this.authUser.uid,
        linkedUserId: this.initiator.uid,
        message,
      },
      newConversationArgs: {
        message,
        users: [CommunityUser.hydrate({ uid: this.initiator.uid }),
          CommunityUser.hydrate({ uid: this.authUser.uid })],
        tempId: '',
        messageTempId: '',
      },
    })
      .then((response) => {
        if (response) {
          this.connectionPending = response;
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.USER,
            StatLoggerActions.ADD,
            '',
            -1,
            this.initiator.uid,
            StatLoggerCategories.CONNECT,
            this.$i18n.locale,
          );
        }
      })
      .catch(() => {
        this.connectionPending = null;
      });
  }

  private openAddConnection(): void {
    this.$bvModal.show(`user-card-add-connection-${this.initiator.uid}`);
  }

  private togglePinState(): void {
    this.removePost(this.uid as string);
    const payload = {
      authUserId: this.authUser.uid,
      attributes: {},
    };
    switch (this.feedType.value) {
      case FeedType.GLOBAL:
        payload.attributes = {
          uid: this.uid,
          pinnedInGlobal: !this.pinned,
        };
        break;
      case FeedType.TOPIC:
        payload.attributes = {
          uid: this.uid,
          pinnedInTopic: !this.pinned,
        };
        break;
      case FeedType.CHANNEL:
        payload.attributes = {
          uid: this.uid,
          pinnedInChannel: !this.pinned,
        };
        break;
      case FeedType.SUB_EDITION:
      case FeedType.EXHIBITOR:
      case FeedType.COMMUNITY_USER:
      case FeedType.PROFILE:
        payload.attributes = {
          uid: this.uid,
          pinnedInEntity: !this.pinned,
        };
        break;
      default:
        break;
    }
    this.updateFeedItemWrapper(payload)
      .then((wrapper) => {
        if (wrapper) {
          if (this.pinned) {
            this.unpinPost(wrapper);
          } else {
            this.pinPost(wrapper);
          }
        }
      });
  }

  private openRemoveConnection(isRepostItem = false): void {
    if (!isRepostItem) {
      this.initiatorUser = this.initiator as CommunityUser;
      this.$bvModal.show(`user-card-remove-connection-${this.initiator.uid}`);
    } else if (this.isRepost) {
      const feedPost = this._feedItems[0].triggered as FeedPost;
      this.initiatorUser = feedPost.authorUser as CommunityUser;
      this.$bvModal.show(`user-card-remove-connection-${feedPost.authorUser?.uid}`);
    }
  }

  private openReport(): void {
    this.$bvModal.show(`report-feed-item-modal-${this.uid}`);
  }

  private beforeDestroy(): void {
    this.$eventsBus.off('remove-feed-up-to-date', this.onRemoveFeedUpToDate);
  }

  private created(): void {
    this.$eventsBus.on('remove-feed-up-to-date', this.onRemoveFeedUpToDate);
  }

  private onRemoveFeedUpToDate(): void {
    this.removeUpToDate = true;
  }
}
